import React, { useState } from 'react';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardHeader from '../../../@coremat/CmtCard/CmtCardHeader';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Typography, useTheme } from '@material-ui/core';
import CmtAvatarGroup from '../../../@coremat/CmtAvatarGroup';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import Box from '@material-ui/core/Box';
import CmtGridView from '../../../@coremat/CmtGridView';
import PropTypes from 'prop-types';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CakeIcon from '@material-ui/icons/Cake';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GenderIcon from '@material-ui/icons/Wc'
import IDIcon from '@material-ui/icons/Fingerprint';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import PendingAction from '@material-ui/icons/AccessTime'
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 3,
      paddingBottom: 0,
    },
  },
  headerTitle: {
    fontSize: '12px'
  },
  tabsList: {
    position: 'relative',
    minHeight: 60,
    '& .MuiTabs-indicator': {
      backgroundColor: alpha(theme.palette.primary.main, 0.8),
    },
  },
  tabItem: {
    maxWidth: 'none',
    minWidth: 10,
    minHeight: 60,
    padding: '5px 10px',
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
  },
  columnRoot: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.5px',
  },
}));

const About = ({ userDetail }) => {
  const [tabValue, setTabValue] = useState('overview');
  const theme = useTheme();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  //const { company, birthday, college, locality, family } = userDetail;
  ////const { profile } = userDetail
  const { country, region, mod_dob, mod_created, nin, unique_num, gender, next_of_kin_rship, next_of_kin_name, next_of_kin_contact, approved} = userDetail;
  const classes = useStyles();

  const renderData = [
    { title: 'UPRS No.', desc: unique_num, icon: <CardMembershipIcon/> },
    { title: 'Birthday', desc: mod_dob, icon: <CakeIcon /> },
    { title: 'Gender', desc: gender, icon: <GenderIcon /> },
    { title: 'lives in', desc: `${region}, ${country}`, icon: <LocationOnIcon /> },
    { title: 'NIN', desc: nin, icon: <IDIcon/> },
    { title: 'Registered On', desc: mod_created, icon: <CalendarTodayIcon /> },
    { title: 'Next of Kin', desc: next_of_kin_name, icon: <PeopleAltIcon /> },
    { title: 'Next of Kin - Contact', desc: next_of_kin_contact, icon: <LocalPhoneIcon /> },
    { title: 'Next of Kin - Relation', desc: next_of_kin_rship, icon: <EmojiPeopleIcon /> },
    /*{
      title: `${family.length} Family Members`,
      list: (
        <Box mt={1}>
          <CmtAvatarGroup
            items={family}
            srcKey="profile_pic"
            avatarSize={24}
            spacing={1}
            max={6}
            titleKey="name"
            renderItemSummary={item => (
              <Typography color="inherit" style={{ fontSize: 10 }}>
                {item.name}
              </Typography>
            )}
          />
        </Box>
      ),
      icon: <PeopleAltIcon />,
    },*/
  ];

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader className={classes.headerTitle}
        separator={{
          color: '#adb5bd',//theme.palette.borderColor.dark,
        }}
        title="About">
        <Box className={classes.tabsList} display="flex" alignItems="center">
          {/*{tabs.map((item, index) => {
            return <Tab className={classes.tabItem} key={index} value={item.slug} label={item.title} />;
          })}*/}
            <Box display="flex" alignItems="center">
                <Tooltip title={approved ? 'Profile Approved': 'Profile is Pending approval'}>
                    {approved ? <VerifiedIcon style={{"color": "#32a852"}}/>: <PendingAction style={{"color":"#c9920e"}}/>}
                </Tooltip>
            </Box>
        </Box>
      </CmtCardHeader>
      <CmtCardContent>
        <CmtGridView
          itemPadding={24}
          responsive={{
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
          }}
          data={renderData}
          renderRow={(item, index) => (
            <Box display="flex" alignItems="center" key={index}>
              <span style={{"color": "#c9920e"}}> {item.icon}</span>
              <Box ml={6}>
                <Box fontSize={12} color="text.secondary">
                  {item.title}
                </Box>
                <Box className={classes.columnRoot}>{item.desc ? item.desc : item.list}</Box>
              </Box>
            </Box>
          )}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default About;

About.prototype = {
  userDetail: PropTypes.object.isRequired,
};
