import React, {Component} from "react";
// @material-ui/core components
import { makeStyles} from "@material-ui/core/styles";
import useAxios from "../../utils/useAxios";
import { BASE_URL } from '../../utils/useAxios';
import countryArr from "utils/Countries";
import banksArray from "utils/Banks"

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import { useState } from "react";
import { useHistory } from 'react-router-dom';

import CardFooter from "components/Card/CardFooter.js";
import IDReactDropzone from "components/Dropzone/IDReactDropzone.js";
import PPReactDropzone from "components/Dropzone/PPReactDropzone.js";

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = {
  cardCategoryWhite: {
    //color: "rgba(255,255,255,.62)",
    color: "#FFFFFF",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardTitleBlack: {
    color: "#000000",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    paddingBottom: "7px",
    textDecoration: "none",
    marginTop: "15px",
    borderBottom: "1px solid #c7c7c7"
  },
  
  backdrop: {
    zIndex: 1000,
    color: '#fff',
  },

};
const useStyles = makeStyles(styles);

const useCardStyles = makeStyles(theme => ({
  mainCard: {
    backgroundColor: "#EEEEEE",
    borderRadius: "3px",
    boxShadow: "none",
    marginTop: "30px",
    marginBottom: "30px"},

  infoCard: {
    boxShadow: "0 2px 2px 0 #b0aeae;",
    borderRadius: "2px",
  }
}));

const useSelectStyles = makeStyles(theme => ({
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
    minWidth: "100%",
  }}));

const useDateStyles = makeStyles(theme => ({
  formControl: {
    paddingBottom: "0",
    margin: "43px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
    minWidth: "100%",
  }}));

const useBoxStyles = makeStyles(theme => ({
  iconView: {
    backgroundColor: "blue",
    color: "500",
    padding: 4,
    borderRadius: 4,
    //'& .MuiSvgIcon-root': {
    //  display: 'block',
    //},
    '&.web': {
      backgroundColor: "warning",
      color: "warning",
    },
    '&.phone': {
      backgroundColor: "success",
      color: "success",
    },
  },
  wordAddress: {
    //wordBreak: 'break-all',
    cursor: 'pointer',
  },
}));


function dateToYMD(date) {
  var d = date.getDate();
  var m = date.getMonth() + 1; //Month from 0 to 11
  var y = date.getFullYear();
  return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}

function getStartingDOB(){
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18); //18 years ago for age of consent
  //return date.toISOString().slice(0,10)
  return dateToYMD(date)
}

const IndividualProfile = (props) => {
  const data = JSON.parse(localStorage.getItem('profileData'))
  const {bank_name, bank_branch, account_name, account_number, mobile_money_number, registered_name} = data.billing[0];

  const classes = useStyles();
  const box_classes = useBoxStyles();
  const card_classes = useCardStyles();
  const select_classes = useSelectStyles();
  const date_classes = useDateStyles();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [dobDate, setDob] = useState(getStartingDOB());
  const [fullName, setFullName] = useState(data.full_name);
  const [alias, setAlias] = useState(data.stage_name);
  //const [dob, setDob] = useState('');
  const [phone, setPhone] = useState(data.phone);
  const [country, setCountry] = useState(data.country);
  const [district, setLocation] = useState(data.district);
  const [gender, setGender] = useState(data.gender);
  const [nin, setNin] = useState(data.nin);
  const [role, setRole] = useState(data.role);
  const [bankName, setBankName] = useState(bank_name? bank_name : '');
  const [bankBranch, setBankBranch] = useState(bank_branch? bank_branch: '');
  const [accountName, setAccountName] = useState(account_name? account_name: '');
  const [accountNumber, setAccountNumber] = useState(account_number? account_number: '');
  const [registeredName, setRegisteredName] = useState(registered_name? registered_name: '');
  const [mobileMoneyNumber, setMobileMoneyNumber] = useState(mobile_money_number? mobile_money_number: '');
  const [facebookHandle, setFacebookHandle] = useState(data.facebook_handle);
  const [twitterHandle, setTwitterHandle] = useState(data.twitter_handle);
  const [instagramHandle, setInstagramHandle] = useState(data.instagram_handle);
  const [nextOfKinName, setKinName] = useState(data.next_of_kin_name);
  const [nextOfKinContact, setKinContact] = useState(data.next_of_kin_contact);
  const [nextOfKinRelationship, setKinRelationship] = useState(data.next_of_kin_rship);
  const [nationalId, setNationalId] = useState([]);
  const [passportPhoto, setPassportPhoto] = useState([]);
  let history = useHistory();

  const api = useAxios();

  const submitHandler = async event => {
    event.preventDefault();
    setLoading(true);
    await api.post(`${BASE_URL}/editIndividualProfile/`, {
      full_name: fullName,
      stage_name: alias,
      dob: dobDate,
      phone: phone,
      country: country,
      district: district,
      gender: gender,
      nin: nin,
      role: role,
      bank_name: bankName,
      bank_branch: bankBranch,
      account_name: accountName,
      account_number: accountNumber,
      registered_name: registeredName,
      mobile_money_number: mobileMoneyNumber,
      facebook_handle: facebookHandle,
      twitter_handle: twitterHandle,
      instagram_handle: instagramHandle,
      next_of_kin_name: nextOfKinName,
      next_of_kin_contact: nextOfKinContact,
      next_of_kin_rship: nextOfKinRelationship,

      national_id: nationalId.length ? nationalId : '',
      passport_photo: passportPhoto.length ? passportPhoto : '',
      passport_page: ""},
      ).then( response => {
        //history.push("/home/profile", { message: response.data.detail })
        window.location.replace("/home/profile")
        setLoading(false)
      }).catch( error => {
        setLoading(false)
        handleErrors(JSON.parse(error.response.data))

      })
      
  }

  const handleDateChange = (date) => {
    const dob = date._d;
    setDob(dateToYMD(dob))
  }

  const handleChangeMultiple = (event) => {
    const values = event.target.value;
    let new_list = []
    for (let i=0, l=values.length; i < l; i += 1){
      if(values[i]){
        new_list.push(values[i]);
      }
    }
    setRole(new_list);
  };


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Paper elevation={2} style={{"paddingBottom": "10px", "paddingTop": "5px", "borderRadius": "3px"}}>
            <CardHeader color="info">
              <p className={classes.cardTitleWhite}>Update profile and save Changes</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Full Name"
                    id="full_name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={fullName}
                    onChange={e => setFullName(e.target.value)}
                    //inputProps={{
                    //  disabled: true,
                    //}}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput className={classes.formControl}
                    labelText="Alias"
                    id="alias"
                    value={alias}
                    onChange={e => setAlias(e.target.value)}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker 
                      className={select_classes.formControl} 
                      value={dobDate} 
                      format="yyyy-MMMM-DD"
                      disableFuture
                      label="Date of Birth"
                      onChange={e => handleDateChange(e)} />
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={select_classes.formControl}>
                    <InputLabel id="gender_label">Gender</InputLabel>
                    <Select
                        labelId="gender_label"
                        id="gender"
                        label="Gender"
                        value={gender}
                        onChange={e => setGender(e.target.value)}
                      >
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Male">Male</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={select_classes.formControl}>
                    <InputLabel id="country_label">Country</InputLabel>
                    <Select
                          labelId="country_label"
                          id="country"
                          label="Country"
                          value={country}
                          onChange={e => setCountry(e.target.value)}
                        >
                        {countryArr.map(({ label, value }) => {
                          return <MenuItem value={label}>{label}</MenuItem>
                          })
                        }
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="District / Area / Town"
                    id="district"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={district}
                    onChange={e => setLocation(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="National ID"
                    id="national_id"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={nin}
                    onChange={e => setNin(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Role"
                    disabled
                    formControlProps={{
                      fullWidth: true,
                      disabled: true
                    }}
                    value={role}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Contact"
                    id="phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                      labelText="Twitter Handle"
                      id="twitter"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={twitterHandle}
                      onChange={e => setTwitterHandle(e.target.value)}
                  />
                  </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Facebook Handle"
                    id="facebook"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={facebookHandle}
                    onChange={e => setFacebookHandle(e.target.value)}
                  />
                </GridItem>
                
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Instagram Handle"
                    id="instagram"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={instagramHandle}
                    onChange={e => setInstagramHandle(e.target.value)}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Next of Kin [Name]"
                    id="next_of_kin_name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={nextOfKinName}
                    onChange={e => setKinName(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Next of Kin [Contact]"
                    id="next_of_kin_contact"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={nextOfKinContact}
                    onChange={e => setKinContact(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Next of Kin [Relationship]"
                    id="next_of_kin_rship"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={nextOfKinRelationship}
                    onChange={e => setKinRelationship(e.target.value)}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={select_classes.formControl}>
                    <InputLabel id="bank_name_label">Bank Name (optional)</InputLabel>
                    <Select
                        labelId="bank_name_label"
                        id="bank_name"
                        label="Bank Name"
                        value={bankName}
                        onChange={e => setBankName(e.target.value)}
                      >
                      {banksArray.map((bank, key) => {
                         return <MenuItem value={bank} key={key}>{bank}</MenuItem>
                      })}
                      
                    </Select>                    
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Branch Name (optional)"
                    id="branch_name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={bankBranch}
                    onChange={e => setBankBranch(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Account Name (optional)"
                    id="account_name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={accountName}
                    onChange={e => setAccountName(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Account Number (optional)"
                    id="account_number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={accountNumber}
                    onChange={e => setAccountNumber(e.target.value)}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Mobile Money Number (optional)"
                    id="mobile_money_number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={mobileMoneyNumber}
                    onChange={e => setMobileMoneyNumber(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Registered Name (optional)"
                    id="registered_name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={registeredName}
                    onChange={e => setRegisteredName(e.target.value)}
                  />
                </GridItem>
              </GridContainer>

              {loading && (
                  <GridContainer>
                    <Backdrop className={classes.backdrop} open={loading} onClick={handleClose}>
                      <CircularProgress size={32} color="danger"/>
                    </Backdrop>
                  </GridContainer>)}

              <GridContainer >
                <GridItem xs={12} sm={12} md={12}>
                  <Typography component="p" style={{"margin": "20px 10px"}}>
                    Attachments
                  </Typography>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <IDReactDropzone setNationalId={setNationalId} onChange={e => setNationalId(e.target.files[0])}>
                  </IDReactDropzone>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <PPReactDropzone setPassportPhoto={setPassportPhoto} onChange={e => setPassportPhoto(e.target.files[0])}>

                  </PPReactDropzone>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button onClick={submitHandler} color="info">Save Changes</Button>
            </CardFooter>
          </Paper>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default IndividualProfile